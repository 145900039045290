import { useState, useEffect } from 'react';

import Link from 'next/link';
import SimpleParallax from 'simple-parallax-js';

import Elipses from '@/assets/icons/icon-dots.svg';
import phoneImg from '@/assets/images/phone-desktop.png';
import phoneMobileImg from '@/assets/images/phone-mobile.png';

const notifications = [
  {
    emoji: '/images/great-news-emoji.png',
    name: 'Put Your Crypto to Work!',
    info: 'Start lending now and earn interest on your assets.',
  },
  {
    emoji: '/images/offer-emoji.png',
    name: "You've Got an Offer!",
    info: 'Review the details to accept the loan.',
  },
  {
    emoji: '/images/put-your-crypto-to-work-emoji.png',
    name: 'Unlock Extra Capital!',
    info: 'One of your assets is eligible for a loan.',
  },
  {
    emoji: '/images/unlock-emoji.png',
    name: 'Great news!',
    info: 'Your portfolio is up by 37%! View your portfolio now.',
  },
];

const HeroSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % notifications.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [notifications.length]);

  return (
    <div className='w-full !bg-midnight-blue lg:!bg-jet-black relative'>
      <div className='[&_div]:absolute [&_div]:w-full hidden md:block'>
        <SimpleParallax scale={1.5}>
          <img src='/images/chart.svg' className='pt-[258px] h-full w-full max-w-[100vw]' alt='chart image' />
        </SimpleParallax>
      </div>
      <div className='banner bg-center-top lg:bg-center-bottom relative z-[10]'>
        <div className='pt-[128px] lg:pt-[125px] max-w-[1366px] m-auto flex justify-between lg:px-5 lg:pl-[78px] lg:pr-[65px] lg:pb-[159px] flex-col lg:flex-row items-center lg:items-start'>
          <div className='lg:max-w-[473px] w-full text-white flex flex-col pt-0 lg:pt-[130px] items-center lg:items-start px-5 lg:px-0'>
            <h3 className='font-bold text-[35px]/[40px] lg:text-[54px]/[51px] max-w-[329px] lg:max-w-full text-center lg:text-start'>Manage & Borrow on One App</h3>
            <p className='text-base/[26px] lg:text-lg font-normal pt-4 block text-center lg:text-start !text-white'>View Your Portfolio and Use Your NFTs and Real-World Assets as Collateral to Borrow Crypto.</p>
            <Link href='/login' className='z-10 min-w-[210px] flex justify-center w-max mt-5 font-semibold text-white text-lg/6 bg-magenta rounded-xl p-5'>
              Create Account
            </Link>
          </div>
          <div className='w-full flex justify-center relative'>
            <img alt='phone' className='w-[388px] h-[756px] relative hidden lg:flex object-contain' src={phoneImg.src} />
            <div className='absolute top-[218px] lg:top-[97px] right-auto lg:right-[-18px] max-w-[397px] w-full lg:w-auto lg:max-w-full z-10'>
              <div className='flex flex-col gap-5 fader-container '>
                {notifications.map(({ emoji, name, info }, idx) => (
                  <div key={idx} className={`absolute right-0 lg:right-[44px] left-0 lg:left-auto h-28 top-[-125px] lg:-top-5 fader-item animate__animated ${activeIndex === idx ? 'animate__fadeInUp' : 'animate__fadeOutUp'}`}>
                    <div className='bg-white rounded-lg px-6 h-[89px] py-[25px] flex justify-between items-center max-w-[397px] lg:min-w-[397px] max-[400px]:mx-5'>
                      <div className='flex gap-[14px]'>
                        <img src={emoji} alt='emoji' className='w-[39px] h-[39px]' />
                        <div>
                          <p className='font-semibold !text-dark-blue text-base'>{name}</p>
                          <p className='text-sm !text-dark-blue font-normal max-w-[280px]'>{info}</p>
                        </div>
                      </div>
                      <Elipses className='-mt-1' />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className='flex lg:hidden w-full justify-center pt-[26px] relative'>
          <div className='[&_div]:absolute [&_div]:h-max [&_div]:w-full block md:hidden [&_div]:!bg-midnight-blue [&_div]:top-2 [&_div]:py-5 [&_div]:scollbar-hide [&_div]:!overflow-visible'>
            <SimpleParallax scale={2.1}>
              <img src='/images/purple-chart-mobile.svg' className='w-full max-w-[100vw]' alt='chart image' />
            </SimpleParallax>
          </div>
          <div className='w-full absolute !transparent h-[245px] bottom-0'></div>
          <img alt='phone' className='w-full min-[440px]:max-w-[388px] relative' src={phoneMobileImg.src} />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
